import React from "react"
import PostLimited from "../../components/PostLimited"

import Layout from "../../components/layout"
import SEO from "../../components/seo"

const Blog = () => (
  <Layout>
    <SEO title="Blog" />
    <PostLimited />
  </Layout>
)

export default Blog
